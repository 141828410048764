<template>
  <div style="background-color: white">
    <div class="top"><img src="../../assets/image/index_02.png" /></div>
    <div class="content" style="">
      <div class="db" style="">
        <router-link  href="#" to="ist" style="color: #ffffff">待办流程</router-link>
      </div>
      <div class="db_list">
        <ul v-for="(item, i) in list" :key="i">
          <li>
            <strong>·</strong>
            <a href="#" @click="toScore(item)">{{ item.node_Title.substring(7, 21) + "..." }}</a>
            <span>{{item.create_Time.substring(0, 10)}}</span>
          </li>
        </ul>
      </div>
      <router-link  href="#" to="addApp">
        <div class="btn1">
          <span><img src="../../assets/image/index_05.png" width="30" alt=""></span>
          新建提案
        </div>
      </router-link >
      <router-link  href="#" to="cppcc">
        <div class="btn2">
            <span><img src="../../assets/image/index_08.png" width="30" alt=""></span>
            我的提案
        </div>
      </router-link >
      <router-link  href="#" to="person">
        <div class="btn3">
            <span><img src="../../assets/image/grzx.png" width="36" alt=""></span>
            个人中心
        </div>
      </router-link >
      <br><br>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import {getList} from "@/api/istManagement";
  import Base64 from "@/util/Base64";
  import {Emas} from "@/assets/js/Emas";

  export default {
    name: "index",
    data() {
      return {
        list: [],
        queryForm: {
          intPageIndex: 1,
          intPageSize: 10,
          strOrderByFileds: 'ID DESC',
        },
        Search: {
          Type: '',
          Case_Type_ID: '',
          Case_ID: '',
          Case_Action: '',
          Staff_Name: '',
          Operator: 0,
        },
      }
    },
    created() {
      this.fetchData()
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/", "首页", "zzdzx.yy.gov.cn/")
    },
    methods: {


      async fetchData() {
        let userInfo = store.getters['user/userInfo']
        this.Search.Operator = userInfo.user_ID
        const { data } = await getList(this.queryForm, this.Search)
        this.list = data.data
        this.flag = this.list.length > 0
      },

      toScore(row) {
        let IST_Node_ID = Base64.encode(row.id)
        let App_ID = Base64.encode(row.pK_Value)
        this.$router.push({
          path: '/score',
          query: {
            App_ID: App_ID,
            IST_Node_ID: IST_Node_ID,
            Case_Action: row.case_Action
          },
        })
      }
    }
  }
</script>

<style scoped>
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }
  body,div,ul,li{ padding:0px; margin:0px; list-style:none; font-size:16px;}
  .top { width:100%; text-align:center;}
  .top img { width:100%}
  .content{background:#fff; position:relative; margin-top:-15px;border-radius: 15px;padding-top: 10px;}
  .db {width:94%;margin-top:10px; background:#e44116;margin:auto; text-align:center; font-size:22px; color:#FFF;line-height:46px;border-top-left-radius:10px;border-top-right-radius:10px;
  }
  .db_list {width:94%; background:#fff4e0; margin:auto; min-height: 240px}
  .db_list ul{ width:94%; margin:auto; padding:8px;}
  .db_list li{ white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
  .db_list li a { color:#000}
  .db_list span { float:right;}
  .db_list strong { color:#e41c12; margin-right:6px;}
  .btn1 {background:#db7849; border-radius:10px;height:86px; line-height:86px; font-size:22px; color:#FFF; text-align:center; width:94%; margin:auto; margin-top:15px;}
  .btn2 {background:#42a98e; border-radius:10px;height:86px; line-height:86px; font-size:22px; color:#FFF; text-align:center; width:94%; margin:auto; margin-top:15px;}
  .btn3 {background:#9574e7; border-radius:10px;height:86px; line-height:86px; font-size:22px; color:#FFF; text-align:center; width:94%; margin:auto; margin-top:15px;}
  .btn1 span,.btn2 span,.btn3 span{position: relative;top: 10px;right: 10px;}
  .btn1 a,.btn2 a,.btn3 a{color:#FFF;}

</style>